const serveleft=[
    {
        id:1,
        picture:"Power",
        title:"Power",
        content:"UPS, Power Banks, Power Stations, Laptop Power Banks, Mobile Chargers, Travel Adapters, Charging Hubs, Charging Cables etc. This category can be further expanded to eVehicles charging solutions."
    },
    {
        id:2,
        picture:"CCTV",
        title:"CCTV Security Surveillance and Accessories",
        content:"Cameras, DVRs, NVRs etc."
    },
    {
        id:3,
        picture:"Optical",
        title:"Optical Products",
        content:"Action Camera, Home Security Camera, IOT Cameras, Mini Projectors etc"
    },
    {
        id:4,
        picture:"Cleaning",
        title:"Purification/Cleaning Products",
        content:"Air Purifiers, Water Purifier, Ioniser, Humidifier, Window Cleaners, Robotic Cleaners etc"
    },
    {
        id:5,
        picture:"IOT",
        title:"IOT",
        content:"Sensors, Doorbells, Security Locks, etc."
    },
    {
        id:6,
        picture:"Fitness",
        title:"Fitness & Wellness",
        content:"Fitness Bands, Fitness Trackers, Smart Watches, Smart Clothing, Massagers, Relaxation Products, Stimulators, Sleep aids, Mind Relaxation, Therapy Products, Meditation Aids etc."
    },
    {
        id:7,
        picture:"Gaming",
        title:"Gaming",
        content:"Mouse, Keyboard, Headphones, Pads, Cases, Gaming Tablets, Gaming Laptops, Gaming Desktops etc."
    } 
]

export default serveleft;