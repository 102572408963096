import React from "react";

function Footer(){
    var a=new Date().getFullYear().toString();
    return (
        <div className="footer">
            <p className="footer-copyright">© {a} Wevolve. All Rights Reserved.</p>
        </div>
        
    )
}

export default Footer;