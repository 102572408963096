import React, {useEffect} from "react"
import Star from "./images/Star.png";
import Serveimg from "./images/Serveimg.png"
import deliveryleft from "./deliveryArrayLeft";
import deliveryright from "./deliveryArrayRight";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);


function deliveryitem(delivery){
    return (
        <p className="delivery-p"> <img className="star-img" src={Star} alt="star" /> {delivery.content}</p>
    )
}

function Deliverable(){

    useEffect(()=>{
        gsap.from('.delivery-img',{
            duration:1,
            x:'20%',
            opacity:0,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.delivery-img',
                start: 'top 100%',
                end: 'top 0',
                toggleActions: 'restart complete forwards reset',
            }
        })
    },[]);

    return (
        <div id="Deliverables" className="delivery">
            <h3 className="delivery-heading">DELIVERABLES</h3>
            <div className="delivery-img-background"></div>
            <img className="delivery-img" src={Serveimg} alt="Serve-img" />
            <dl className="delivery-c-left">{deliveryleft.map(deliveryitem)}</dl>
            <dl className="delivery-c-right">{deliveryright.map(deliveryitem)}</dl>
        </div>
    )
}

export default Deliverable;