import React, {useRef, useEffect} from "react"
import principal from "./principalArray";
import Amitji from "./images/Amitji.png";
import Amitjimobileview from "./images/Amitjimobileview.png";
import tick from "./images/tick.png"
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

function principalitem(principal){
    var a="principal-p principal-p"+principal.id.toString();
    return (
        <div>
            <p className={a}>
                <img className="tick-img" src={tick} alt="tick" />
                <div className="principal-block"><span className="principal-title">{principal.title}</span> {principal.content}</div>
            </p>
        </div>
    )
}

function Principals(){
    let arr=[".principal-p1",".principal-p2",".principal-p3",".principal-p4",".principal-p5",".principal-p6"];
    useEffect(()=>{
        gsap.from('.amit-ji-background',{
            duration:1,
            opacity:0,
            delay: 1.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.principal',
                start: 'top 100%',
                end: 'top -100%',

                toggleActions: 'restart complete forwards reset',
            }
        })
    gsap.from('.amit-ji-img',{
        duration:1,
        x:'100',
        opacity:0,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -100%',
            toggleActions: 'restart complete forwards reset',
        }
    })
    gsap.from('.principal-p1',{
        duration:0.5,
        opacity:0,
        delay:1,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -100%',
            toggleActions: 'restart complete forwards reset',
        }
    })
    gsap.from('.principal-p2',{
        duration:0.5,
        opacity:0,
        delay:1.5,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -200%',
            toggleActions: 'restart complete forwards reset',
        }
    })
    gsap.from('.principal-p3',{
        duration:0.5,
        opacity:0,
        delay:2,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -200%',
            toggleActions: 'restart complete forwards reset',
        }
    })
    gsap.from('.principal-p4',{
        duration:0.5,
        opacity:0,
        delay:2.5,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -200%',
            toggleActions: 'restart complete forwards reset',
        }
    })
    gsap.from('.principal-p5',{
        duration:0.5,
        opacity:0,
        delay:3,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -200%',
            toggleActions: 'restart complete forwards reset',
        }
    })
    gsap.from('.principal-p6',{
        duration:0.5,
        opacity:0,
        delay:3.5,
        ease: 'ease-in',
        scrollTrigger:{
            trigger:'.principal',
            start: 'top 100%',
            end: 'top -200%',
            toggleActions: 'restart complete forwards reset',
        }
    })
},[])


    
    return (
        <div id="Principals" className="principal">
            <h3 className="principal-heading">OUR PRINCIPAL CONSULTANT</h3>
            <div className="amit-ji-background"></div>
            <img className="amit-ji-mobile-view" src={Amitjimobileview} alt="Amit ji Image" />
            <img className="amit-ji-img" src={Amitji} alt="Amit ji" />
            <dl className="principal-c">{principal.map(principalitem)}</dl>
        </div>
    )
}

export default Principals;