import React from "react";
import i1 from "./images/Gallery/i1.png"
import i2 from "./images/Gallery/i2.png"
import i3 from "./images/Gallery/i3.png"
import i4 from "./images/Gallery/i4.png"
import i5 from "./images/Gallery/i5.png"
import i6 from "./images/Gallery/i6.png"


const colors=[i1,i2,i3,i4,i5,i6];
const delay=6000;

function Gallery() {
  const [index, setIndex] = React.useState(0);
  const [stop,setStop] = React.useState(false);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    if(!stop){
      resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
    }
  }, [index, stop]);

  return (
    <div className="slideshow">
      <div
        onMouseEnter={()=>setStop(true)}
        onMouseLeave={()=>setStop(false)}
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {colors.map((backgroundColor, index) => (
          <div
            className="slide"
            key={index}
            // style={{ backgroundColor }}
          >
            <img className="slide" src={backgroundColor} alt="" />
          </div>
        ))}
      </div>

      <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
