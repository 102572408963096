const deliveryright=[
    {
        id:1,
        content:"Product strategy, roadmap planning and execution."
    },
    {
        id:2,
        content:"Budget recommendation and planning."
    },
    {
        id:3,
        content:"Supplier and product discovery."
    },
    {
        id:4,
        content:"Supplier and product selection."
    },
    {
        id:5,
        content:"Customised/ODM products and solutions."
    },
    {
        id:6,
        content:"Market research and competetive analysis."
    },
    {
        id:7,
        content:"Successfully launched more than 700 products over last 10 years."
    },
    {
        id:8,
        content:"International technology and product trend analysis."
    },
    {
        id:9,
        content:"Align with top chipset vendors, IDHs, solution providers."
    },
    {
        id:10,
        content:"Product testing, customisation, quality assurance and documentation."
    } 
]

export default deliveryright;