import React, {useRef, useEffect, useState} from "react"
import strength from "./strengthArray";
import Ribbon from "./images/strength/ribbon.png"
import LeftArrow from "./images/strength/leftArrow.png"
import RightArrow from "./images/strength/rightArrow.png"
// import gsap from "gsap";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const delay=6000;

var ind=[0,1,2,3,4,5,6,7,8,9];

function strengthitem(strength){
    var a="strength-element strength-element"+strength.id.toString();
    return (
        <div className={a}>
        <div className="strength-content-above"></div>
        <div className="strength-content">
        <div className="strength-content-h" >{strength.heading}</div>
        <div className="strength-content-c">{strength.content}</div>
        </div>
        </div>
        )
    }


function Strength(){

            const [i, setI]=useState(0);
            const headerRef= useRef(null);
            useEffect(()=>{
                gsap.from('.strength-heading',{
            duration:1,
            x:'50%',
            opacity:0,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength-heading',
                start: 'top 100%',
                end: 'top 0',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element1',{
            duration:1,
            opacity:0,
            delay:1,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element2',{
            duration:1,
            opacity:0,
            delay:1.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element3',{
            duration:1,
            opacity:0,
            delay:2,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element4',{
            duration:1,
            opacity:0,
            delay:2.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element5',{
            duration:1,
            opacity:0,
            delay:3,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element6',{
            duration:1,
            opacity:0,
            delay:3.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element7',{
            duration:1,
            opacity:0,
            delay:4,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element8',{
            duration:1,
            opacity:0,
            delay:4.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element9',{
            duration:1,
            opacity:0,
            delay:5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.strength-element10',{
            duration:1,
            opacity:0,
            delay:5.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.strength',
                start: 'top 100%',
                end: 'top -200%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        
    },[]);

    function increase(){
        var a=i+1;
        a=a%10;
        setI(a);
    }
    function decrease(){
        var a=i-1+10;
        a=a%10;
        setI(a);
    }
    return (
        <div id="Strengths" className="strength">
            <img className="strength-background" src={Ribbon} alt="Strength Image" />
            <h3 ref={headerRef} className="strength-heading">STRENGTH</h3>
            <hr className="strength-hr" />
            <div className="strength-c-mobile">
                <img className="strength-c-mobile-left" onClick={increase} src={LeftArrow} alt="leftArrow" />
                <img className="strength-c-mobile-right" onClick={decrease} src={RightArrow} alt="rightArrow" />
                <div className="strength-c">{strengthitem(strength[i])}</div>    
            </div>
            <div className="strength-c-desktop strength-c">{strength.map(strengthitem)}</div>
        </div>
        )
    }

    export default Strength;