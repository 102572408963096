const serveright=[
    {
        id:8,
        picture:"Mobile",
        title:"Mobile Accessories",
        content:"Power Banks, Adapters, Cables, Earphones, Type-C Products, Mobile Cases etc."
    },
    {
        id:9,
        picture:"Audio",
        title:"Audio Products",
        content:"Portable Speakers, Bluetooth Earphones/ Headsets, ANC, TWS, Multimedia 2.1/4.1/5.1 Speakers, Party Speakers etc."
    },
    {
        id:10,
        picture:"Hybrid",
        title:"Hybrid Products",
        content:"Multi-function products like Clock+Speaker, Router+Speaker etc."
    },
    {
        id:11,
        picture:"Tablet",
        title:"Tablets",
        content:"Wi-Fi, 4G, 5G Tablets, Foldable Tablets etc."
    },
    {
        id:12,
        picture:"Laptop",
        title:"Laptops & AIOs",
        content:"Intel/AMD laptops, All-in-ones (AIO), Qualcomm based laptops."
    },
    {
        id:13,
        picture:"IT",
        title:"IT Peripherals",
        content:"Mouse, Keyboard, Desksets, Cabinets, SMPS, Pen Tablets, Web Cameras, Document Scanners, Barcode Scanners, USB Hubs, Card Readers, Laptop Adapters, LED Monitors."
    },
    {
        id:14,
        picture:"Network",
        title:"Networking Active & Passive Products",
        content:"Routers, Switches, Repeaters, Epon/Gpon, Cables, Passive accessories etc."
    }
]

export default serveright;