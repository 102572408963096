import React from "react";
import Gallery from "./Gallery";
import Overview from "./Overview";
import Deliverable from "./Deliverable";
import Strength from "./Strength";
import Serve from "./Serve";
import Principals from "./Principals";
import Contact from "./Contact";

function Body(){
    return (
        <div>
            <Gallery></Gallery>
            <Overview></Overview>
            <Deliverable></Deliverable>
            <Strength></Strength>
            <Serve></Serve>
            <Principals></Principals>
            <Contact></Contact>
        </div>
    )
}

export default Body;