const deliveryleft=[
    {
        id:11,
        content:"Product ID, MD, Tooling, PCBA, Software, Assembly Services."
    },
    {
        id:12,
        content:"Improving user experience with better finishes, colours, materials, detailing, unboxing experience."
    },
    {
        id:13,
        content:"Alignment with international and domestic suppliers."
    },
    {
        id:14,
        content:"Reduce costs at BOM level, operations, logistics etc."
    },
    {
        id:15,
        content:"Quality Control (QC), Quality Assurance (QA), Pre-Delivery Inspection (PDI) services."
    },
    {
        id:16,
        content:"Compliances and certifications."
    },
    {
        id:17,
        content:"Promotion and key sale event planning and recommendations."
    },
    {
        id:18,
        content:"Go to market recommendations and services."
    },
    {
        id:19,
        content:"Service, RMA, Spare management recommendations."
    }
]

export default deliveryleft;