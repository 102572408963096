import React from "react";
import Calender from "./images/contact/contact-info-icons/calender.png"
import Mail from "./images/contact/contact-info-icons/mail.png";
import Phone from "./images/contact/contact-info-icons/phone.png";
import Time from "./images/contact/contact-info-icons/time.png";
import Facebook from "./images/contact/contact-info-icons/contact-info-links/facebook.png";
import Insta from "./images/contact/contact-info-icons/contact-info-links/insta.png";
import Linkdin from "./images/contact/contact-info-icons/contact-info-links/linkdin.png";

function Contact(){
    return (
        <div id="Contact" className="contact">
            <h3 className="contact-heading">Contact Us</h3>
            <div className="contact-background"></div>
            <div className="contact-map"><iframe className="contact-map-element" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241008.7608358486!2d72.73363081970277!3d19.292718345763582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7a566771bb707%3A0x5b73a648f8fbe777!2sBrandworks%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1678098186110!5m2!1sen!2sin"  height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="contact-mailandinfo">
                <div className="contact-mailus">
                    <p className="contact-mailus-heading">WRITE TO US</p>
                    <form className="contact-mailus-form" action="mailto:nikita.kumawat@bwtech.in" method="post"  encType="text/plain">
                        <input className="contact-mailus-elements" type="text" name="yourName"  placeholder="Name" /><br />
                        <input className="contact-mailus-elements" type="text" name="yourTelephone"  placeholder="Contact Number"/><br />
                        <input className="contact-mailus-elements" type="email" name="yourEmail" placeholder="Email"/><br />
                        <textarea className="contact-mailus-elements contact-mailus-textarea" name="yourMessage" cols="30" placeholder="Message" rows="3"></textarea><br />
                        <button className="contact-mailus-submit" type="submit" ><span>Submit</span></button>
                    </form>
                </div>
                <div className="contact-info">
                    <p className="contact-info-heading">CONTACT INFO</p>
                    <p className="contact-info-element"><img className="contact-info-element-icon" src={Phone} alt="" />+91 98205 37590</p>
                    <p className="contact-info-element"><img className="contact-info-element-icon" src={Calender} alt="" /> Monday to Saturday</p>
                    <p className="contact-info-element"><img className="contact-info-element-icon" src={Time} alt="" />10:00 AM - 7 PM IST</p>
                    <p className="contact-info-element"><img className="contact-info-element-icon" src={Mail} alt="" />amit@wevolve.biz</p>
                    <hr className="contact-info-hr" />
                    <div className="contact-info-links">
                        <a className="contact-info-link" href="https://www.facebook.com/brandworksvisiontofly"><img className="contact-info-link-img" src=   {Facebook} alt="" /></a>
                        <a className="contact-info-link" href="https://www.instagram.com/brandworks_technologies/"><img className="contact-info-link-img" src={Insta} alt="" /></a>
                        <a className="contact-info-link" href="https://www.linkedin.com/company/80026467/"><img className="contact-info-link-img" src={Linkdin} alt="" /></a>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Contact;