const strength=[
    {
        id:1,
        key:1,
        serial:1,
        heading:"Strong Backing",
        content:"Backed by one of the largest OEM/ODM manufacturers in Western India."
    },
    {
        id:2,
        key:2,
        serial:2,
        heading:"Excellent Legacy of Product Launches",
        content:"Successfully launched more than 700 products over last 10 years"
    },
    {
        id:3,
        key:3,
        serial:3,
        heading:"Unmatched Expertise",
        content:"In-depth knowledge and extensive hands- on experience in dealing with China, Taiwan & India ecosystem with a focus on sourcing, managing commercials and seamless execution adhering to high quality assurance standards."
    },
    {
        id:4,
        key:4,
        serial:4,
        heading:"Key Strategic Partnerships",
        content:"Key Strategic Partnerships – Extensive experience in working with top chipset and solution providers like Intel, Qualcomm, Microsoft, Google, Mediatek, Spreadtrum and Airoha to design and develop custom offerings."
    },
    {
        id:5,
        key:5,
        serial:5,
        heading:"Innovator's DNA",
        content:"Innovative Products with many Firsts across categories – Instrumental in identifying and developing innovative products with over 80 products launched for the 1st time in India across categories and some even 1st time in the World."
    },
    {
        id:6,
        key:6,
        serial:6,
        heading:"High Quality & Reliability",
        content:"Quality and Reliability - are topmost things that will build a brand and have long recall. We have strong expertise to fulfil this."
    },
    {
        id:7,
        key:7,
        serial:7,
        heading:"Focus on Optimization & Efficiency",
        content:"Cost Savings – This is a regular process, better payment terms, BOM level cost reductions, Service cost reductions, Operational efficiencies are our strengths."
    },
    {
        id:8,
        key:8,
        serial:8,
        heading:"On Time, in Full",
        content:"Timeline Fulfilment – Launch products within defined timelines, proper tracking of projects and meeting customers deadlines."
    },
    {
        id:9,
        key:9,
        serial:9,
        heading:"Enhanced User Experience",
        content:"Better User Experience – Expertise in increasing user experience with better finishes, colours, materials, detailing, unboxing experience."
    },
    {
        id:10,
        key:10,
        serial:10,
        heading:"Choosing Promotional Events smartly",
        content:"Key Market Promotion events planning – Proper plan needs to be placed for all key promotional and launch events. New product launches should be planned carefully to catch these events. Rushing always leads to failures. We have good knowhow to handle this."
    }
]

export default strength;