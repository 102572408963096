import React from "react"
import Overviewimg from "./images/Overviewimg.png"

function Overview(){
    return (
        <div id="Overview" className="overview">
            <h3 className="overview-heading">OVERVIEW</h3>
            <img className="overview-img" src={Overviewimg} alt="Overview Image" />
            <p className="overview-p1">Wevolve aims to partner up with brands and companies
            in their growth journey. We are a strong team of
            professionals with over 50 years of combined experience
            in dealing with Product Sourcing, Product Development,
            Supply Chain Management, R&D, Quality Assurance,
            Product Management, Product Repairing across varied
            segments like Consumer Electronics, IT Electronics,
            Mobile Phones, Tablets, Laptops and Mobile Accessories,
            IoT, Gaming and more.</p>
            <p className="overview-p2">We continuously monitor and keep a track of what is
            happening globally and what global brands of similar
            fields are doing. Keeping pace with innovation and
            technologies is very important, this helps us to suggest
            our customers the roadmap and product life cycles.</p>
        </div>
    )
}

export default Overview;