const principal=[
    {
        id:1,
        title:"Founding Member of iBall,",
        content:"one of largest Indian brands in the field of Digital Lifestyle Products, IT Accessories & Peripherals business with presence in more than 25 product categories."
    },
    {
        id:2,
        title:"Over 25 years of experience",
        content:"in dealing with Product Sourcing, Product Development, Supply Chain Management, R&D, Quality Assurance and Product Management across varied segments like Consumer Electronics, IT Electronics, Mobile Phones, Tablets, Laptops and Mobile Accessories."
    },
    {
        id:3,
        title:"In-depth knowledge and extensive hands-on experience",
        content:" in dealing with China & Taiwan ecosystem with a focus in sourcing, managing commercials and seamless execution adhering to high quality assurance standards."
    },
    {
        id:4,
        title:"Key Strategic Partnership - ",
        content:"Extensive experience in working with top chipset and solution providers like Intel, Qualcomm, Microsoft, Google, Mediatek, Spreadtrum and Airoha to design and develop custom offerings for iBall."
    },
    {
        id:5,
        title:"Innovative Products ",
        content:"with many Firsts across categories – Instrumental in identifying and developing innovative products with over 80 products launched for the 1st time in India across categories and some even 1st time in the World."
    },
    {
        id:6,
        title:"Category Leadership - ",
        content:"Instrumental in ensuring that iBall sustained the leadership position to be amongst top 3 leaders in the Tablet PCs category."
    }
]

export default principal;