import React, {useState} from "react";
import logo from "./images/Logo.png"
import Equi from "./images/contact/contact-info-icons/nav/equi.png"

function Header(){
    var a="n-e-m n-e-m-hidden";
    var b="n-e-m";
    var [C, setC]=useState(a);
    var d="nav-element-plus-minus nav-element-hid";
    var e="nav-element-plus-minus"
    var [F, setF]=useState(e);
    var [G, setG]=useState(d);
    function toggle(){
        if(C===a){
            setC(b);
            setF(d);
            setG(e);
        }
        else{
            setC(a);
            setF(e);
            setG(d);
        }
    }
    return (
        <div className="nav">
            <img className="nav-logo" src={logo} alt="Logo" />
            <div className="nav-elements">
                <a className="nav-element" href="#Overview">OVERVIEW</a>
                <a className="nav-element" href="#Deliverables">DELIVERABLES</a>
                <a className="nav-element" href="#Strengths">STRENGTHS</a>
                <a className="nav-element" href="#Serve">CATEGORIES</a>
                <a className="nav-element" href="#Principals">CONSULTANT</a>
                <a className="nav-element nav-contact" href="#Contact">Contact Us</a>
            </div>
            <div className="nav-elements-m">
                <div onClick={toggle} className={F}>+</div>
                <div onClick={toggle} className={G}>-</div>
                <div className={C}>
                    <a onClick={toggle} className="nav-element-m" href="#Overview">OVERVIEW</a>
                    <a onClick={toggle} className="nav-element-m" href="#Deliverables">DELIVERABLES</a>
                    <a onClick={toggle} className="nav-element-m" href="#Strengths">STRENGTHS</a>
                    <a onClick={toggle} className="nav-element-m" href="#Serve">CATEGORIES</a>
                    <a onClick={toggle} className="nav-element-m" href="#Principals">CONSULTANT</a>
                    <a onClick={toggle} className="nav-element-m-contact" href="#Contact">Contact Us</a>
                </div>
            </div>
        </div>
        // <h1>Header</h1>
    )
};

export default Header;