import React, {useEffect} from "react"
import Serveimg from "./images/Serveimg.png"
import serveleft from "./serveArrayLeft";
import serveright from "./serveArrayRight";
import Audio from "./images/Audio.png";
import Cleaning from "./images/Cleaning.png";
import Fitness from "./images/Fitness.png";
import Gaming from "./images/Gaming.png";
import Hybrid from "./images/Hybrid.png";
import IOT from "./images/IOT.png";
import IT from "./images/IT.png";
import Laptop from "./images/Laptop.png";
import Mobile from "./images/Mobile.png";
import Network from "./images/Network.png";
import Optical from "./images/Optical.png";
import Power from "./images/Power.png";
import Tablet from "./images/Tablet.png";
import CCTV from "./images/CCTV.png";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

function serveitem(serve){
    var a=serve.picture;
    var b;
        if(a==="Audio"){
            b= Audio;
        };
    if(a==="Cleaning"){
        b= Cleaning;
    };
    if(a==="Fitness"){
        b= Fitness;
    };
    if(a==="Gaming"){
        b= Gaming;
    };
    if(a==="Hybrid"){
        b= Hybrid;
    };
    if(a==="IOT"){
        b= IOT;
    };
    if(a==="IT"){
        b= IT;
    };
    if(a==="Laptop"){
        b= Laptop;
    };
    if(a==="Mobile"){
        b= Mobile;
    };
    if(a==="Network"){
        b= Network;
    };
    if(a==="Optical"){
        b= Optical;
    };
    if(a==="Power"){
        b= Power;
    };
    if(a==="Tablet"){
        b= Tablet;
    };
    if(a==="CCTV"){
        b= CCTV;
    }  ;
    var c=serve.id;
    var d="serve-c serve-c"+serve.id.toString();
    return (
        <div className={d}>
            <img className="serve-c-img" src={b} alt="Picture" />
            <p className="serve-c-title">{serve.title} -</p>
            <p className="serve-c-content">{serve.content}</p>
        </div>
    )
}

function Serve(){

    useEffect(()=>{
        gsap.from('.serve-img',{
            duration:1,
            x:'-100',
            opacity:0,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve-img',
                start: 'top 100%',
                end: 'top 0',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c1',{
            duration:0.5,
            opacity:0,
            x:'-30%',
            delay:0,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c2',{
            duration:0.5,
            opacity:0,
            x:'-30%',
            delay:0.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c3',{
            duration:0.5,
            opacity:0,
            x:'-30%',
            delay:1,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c4',{
            duration:0.5,
            opacity:0,
            delay:1.5,
            x:'-30%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c5',{
            duration:0.5,
            opacity:0,
            delay:2,
            x:'-30%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c6',{
            duration:0.5,
            opacity:0,
            delay:2.5,
            x:'-30%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c7',{
            duration:0.5,
            opacity:0,
            delay:3,
            x:'-30%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c8',{
            duration:0.5,
            opacity:0,
            x:'27.5%',
            delay:3.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c9',{
            duration:0.5,
            opacity:0,
            x:'27.5%',
            delay:4,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c10',{
            duration:0.5,
            opacity:0,
            x:'27.5%',
            delay:4.5,
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c11',{
            duration:0.5,
            opacity:0,
            delay:5,
            x:'27.5%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c12',{
            duration:0.5,
            opacity:0,
            delay:5.5,
            x:'27.5%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c13',{
            duration:0.5,
            opacity:0,
            delay:6,
            x:'27.5%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
        gsap.from('.serve-c14',{
            duration:0.5,
            opacity:0,
            delay:6.5,
            x:'27.5%',
            ease: 'ease-in',
            scrollTrigger:{
                trigger:'.serve',
                start: 'top 100%',
                end: 'top -100%',
                toggleActions: 'restart complete forwards reset',
            }
        })
    },[]);



    return (
        <div id="Serve" className="serve">
            <h3 className="serve-heading">CATEGORIES WE SERVE</h3>
            <img className="serve-img" src={Serveimg} alt="Serve-img" />
            <div className="serve-img-background"></div>
            <p className="serve-c-left">{serveleft.map(serveitem)}</p>
            <p className="serve-c-right">{serveright.map(serveitem)}</p>
        </div>
    )
}

export default Serve;